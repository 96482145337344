.user-container {
    width: min(100%, 430px);
    margin: 0 auto;
    background-color: white;
}

.user-container * {
    margin: 0;
    padding: 0;
}



.user-cover {
    width: 100%;
    height: 150px;
    position: relative;
}

.user-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.user-cover h1 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    left: 20px;
    margin: 0;
    z-index: 2;
}

.user-bio {
    padding: 15px;
    position: relative;
    z-index: 2;
}

.user-bio img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    border: 4px solid white;
    background-color: white;
}

.user-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 70px;
    align-items: center;
}

.user-details p {
    width: 80%;
    text-align: center;
    color: rgb(100, 100, 100);
}

.user-details .follow-btn {
    background-color: black;
    color: white;
    border: none;
    padding: 12px 40px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-block: 15px;
}