.user-content {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 15px;
    display: flex;
    gap: 15px;
}

.user-content-left,
.user-content-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(50% - 7.5px);
    margin-block: 15px;
}

.user-content a {
    max-width: 100%;
}

.user-content canvas,
.user-content video {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}