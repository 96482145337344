.products {
  position: absolute;
  width: 100%;
  height: fit-content;
  left: 0;
  bottom: 0;
  background-color: whitesmoke;
  border-radius: 30px 30px 0 0;
  padding-bottom: 25px;
  box-sizing: border-box;
  transition-duration: 1s;
}
.products.hidden {
  transform: translateY(200%) !important;
}

.products::before {
  content: "";
  position: absolute;
  width: 25%;
  height: 5px;
  border-radius: 10px;
  background-color: gray;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}

.products h2 {
  margin: 0;
  padding-top: 45px;
  padding-inline: 25px;
  padding-bottom: 15px;

  width: 100%;
  box-sizing: border-box;
}

.product-container {
  display: grid;
  grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
  box-sizing: border-box;
  gap: 15px;
  width: 100%;
  max-height: 87vh;
  overflow: auto;
  padding: 10px 20px;
}

.product {
  background-color: rgb(250, 250, 250);
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.product img {
  width: 100%;
  height: min(calc(0.45 * 100vw), 200px);
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.product .product-info {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: space-between;
}

.product .product-info h3 {
  margin: 0;
  font-size: 0.95em;
  font-weight: 600;
}

.product .product-info p {
  position: relative;
}

.product .product-info span,
.product .product-info i {
  margin: 0;
  font-size: 0.9em;
  color: rgb(255, 53, 87);
  margin-right: 2px;
  font-weight: bold;
}
.product .product-info span.strike-through {
  text-decoration: line-through;
  color: gray;
  font-size: 0.85em;
  margin-right: 1px;
  font-weight: 300px;
}
.product .product-info a {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 53, 87);
  padding: 0.4em 0.8em;
  color: white;
  font-size: 0.9em;
  text-decoration: none;
}

@media (max-width: 370px) {
  .product {
    font-size: 0.8rem;
  }
}

.products .dummy-white {
  height: max(100vh, 500px);
  position: absolute;
  top: 100%;
}
