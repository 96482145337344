.product-card {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 135px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 0;
  cursor: pointer;
}
.product-card.outlined {
  padding: 10px;
  cursor: default;
  border: 1px solid rgb(97, 97, 97);
}

.product-card input {
  appearance: none;
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  -o-appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid gray;
  padding: 2px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin: 0;
}
.product-card input::after {
  content: "";
  display: block;
  background-color: transparent;
  position: absolute;
  left: -1px;
  top: -1px;
  height: calc(100%);
  width: calc(100%);
  border-radius: 50%;
}
.product-card input:checked {
  border-color: rgb(255, 53, 87);
  background-color: rgb(255, 53, 87);
}
.product-card input:checked::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.product-card .product-image {
  max-width: min(80px, 25%);
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-card img {
  width: 100%;
  object-fit: cover;
}

.product-card .product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.product-card .product-details h4 {
  margin-block: 5px;
  font-size: 1em;
}
.product-card .product-details p {
  margin: 0;
  font-size: 0.85em;
  width: 95%;
  color: rgb(145, 145, 145);
}
.product-card .product-details span {
  font-weight: 500;
  margin-right: 5px;
  font-size: 0.95em;
}
.product-card .product-details span.strike-through {
  font-size: 0.92em;
  text-decoration: line-through;
  color: rgb(145, 145, 145);
}

.product-card button {
  position: absolute;
  right: 12px;
  top: 10px;
  border: none;
  background-color: transparent;
  font-size: 0.95em;
  cursor: pointer;
}
.product-card button:hover {
  scale: 1.1;
}
