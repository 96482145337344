.upload-container .shadowed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.4s;
}
.upload-container .shadowed-overlay.hidden {
  opacity: 0;
  pointer-events: none;
}

.upload-options-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.5s;
  background-color: #fff;
  height: fit-content;
  border-radius: 30px 30px 0 0;
  z-index: 2;
}
.upload-options-container.active {
  transform: translateY(0);
}
.upload-options-container * {
  box-sizing: border-box;
}

.upload-options-header {
  position: relative;
  width: 100%;
  padding: 10px 15px;
}
.upload-options-header h2 {
  text-align: center;
  font-size: 1.2em;
}
.upload-options-header button {
  position: absolute;
  top: 50%;
  right: 0.8em;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(97, 97, 97);
}

.upload-container .options {
  margin-bottom: 2em;
}

.upload-options-container .option {
  width: 100%;
  padding: 0.7em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-container .option h4 {
  margin: 0.5em 0;
}
.upload-container .option h4 span {
  color: rgb(151, 151, 151);
}
.upload-options-container .slider-toggle {
  position: relative;
  width: 3em;
  height: 1.5em;
  border-radius: 0.75em;
  padding: 4px;
  background-color: rgb(184, 184, 184);
  transition-duration: 0.3s;
}
.upload-options-container .slider-toggle label {
  width: calc(1.5em - 8px);
  height: calc(1.5em - 8px);
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: transform 0.5s;
}
.upload-options-container .slider-toggle input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.upload-options-container .slider-toggle input:checked + label {
  transform: translateX(1.5em);
  background-color: rgb(255, 53, 87);
}
.upload-container .slider-toggle:has(input:checked) {
  background-color: rgb(255, 170, 184);
}
.upload-container .option input.radio-input {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}
.upload-container .option input.radio-input:checked {
  accent-color: rgb(255, 53, 87);
}
