.existing-products-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: white;
}

.existing-products-body {
    height: calc(100% - 70px);
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    overflow: auto;
}