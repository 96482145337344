.login-container {
  height: max(100vh, 500px);
  width: min(100%, 430px);
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-color: rgb(221, 221, 221);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container * {
  box-sizing: border-box;
}

.login-options button,
.login-box button {
  display: block;
  padding: 15px 20px;
  width: 200px;
  margin: 40px auto;
  border-radius: 10px;
  border: none;
  background-color: rgb(50, 50, 50);
  font-weight: 700;
  color: white;
  font-size: 0.95em;
  cursor: pointer;
}

.login-box,
.login-options {
  padding: 30px 20px;
  width: 90%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.login-box h2 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
}
.login-box button {
  width: 40%;
  padding: 10px 15px;
  margin: 0 auto;
  margin-top: 40px;
}
