.new-product-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.new-product-container * {
  box-sizing: border-box;
}

.new-product-container .upload-images {
  margin-top: 10px;
  width: 100%;
  height: 150px;
  padding: 10px 20px;
}
.new-product-container .upload-images .image-uploader {
  width: 100%;
  height: 100%;
  border: 2px dashed rgb(201, 201, 201);
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.new-product-container .upload-images .image-uploader input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.new-product-container .upload-images .image-uploader label {
  position: absolute;
  width: 80%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 96, 123);
}

.new-product-container > button {
  width: 90%;
  margin: 15px auto;
  margin-bottom: 25px;
  border: none;
  border-radius: 7px;
  background-color: rgb(255, 53, 87);
  padding: 12px 20px;
  font-size: 0.95em;
  font-weight: 700;
  color: white;
}

.new-product-container .product-data {
  width: 100%;
  padding: 10px;
}

.input-group {
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  position: relative;
}
.input-group input {
  width: 100%;
  padding: 0.8em 1.2em;
  font-size: 0.95em;
  border-radius: 15px;
  border: 1px solid rgb(70, 70, 70);
}
.input-group label {
  padding: 4px;
  margin: 0;
  position: absolute;
  top: 0;
  left: max(5%, 15px);
  font-size: 0.75em;
  transform: translateY(-50%);
  color: rgb(70, 70, 70);
  background-color: white;
}

.half-input-group {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 12px;
}

.template-display {
  padding: 5px 20px;
}
.template-display .product-card .product-image {
  background-color: rgb(240, 240, 240);
}
.product-card .product-image.isempty {
  width: min(80px, 25%);
  background-color: rgb(245, 245, 245);
}
