.upload-container {
  width: min(100%, 430px);
  height: max(100vh, 500px);
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}
.upload-container * {
  box-sizing: border-box;
}

.upload-header {
  width: 100%;
  position: relative;
  text-align: center;
  padding-block: 1em;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  height: 60px;
}
.upload-header button {
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 1.3em;
  cursor: pointer;
}
.upload-header h2 {
  margin: 0;
}

.upload-content {
  width: 100%;
  padding: 1em 1.2em;
  height: 200px;
  position: relative;
  display: grid;
  grid-template-columns: calc(70% - 10px) 30%;
  gap: 10px;
}
.upload-content::after {
  content: "";
  display: block;
  position: absolute;
  width: 90%;
  height: 1px;
  background-color: rgb(201, 201, 201);
  bottom: 0;
  left: 5%;
}
.upload-content textarea {
  border: none;
  resize: none;
  font-size: 0.95em;
  padding: 10px;
}
.upload-content textarea:focus {
  outline: none;
}
.upload-content .upload-video {
  border-radius: 10px;
  overflow: hidden;
}
.upload-content .upload-video .video-uploader {
  width: 100%;
  height: 100%;
  border: 2px dashed rgb(201, 201, 201);
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.upload-content .upload-video .video-uploader input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.upload-content .upload-video .video-uploader label {
  position: absolute;
  width: 80%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 96, 123);
}
.upload-content .upload-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-container .upload-controls {
  padding-top: 15px;
  max-height: calc(100svh - 200px - 60px - 82px);
  overflow: auto;
}
.upload-container .upload-control {
  width: 100%;
  padding: 0.5em 1em;
  position: relative;
  display: flex;
  margin-bottom: 5px;
}
.upload-controls div h4 {
  margin: 0;
  margin-bottom: 0.5em;
}
.upload-controls i.icon {
  font-size: 1em;
  margin-right: 10px;
  margin-top: 1px;
}
.upload-control p {
  margin: 0;
  font-size: 0.9em;
  width: calc(90% - 1em - 10px);
  color: rgb(145, 145, 145);
}
.upload-control button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  color: rgb(145, 145, 145);
}
.upload-controls .products-container {
  width: 100%;
  padding: 0 20px;
}
.upload-controls .products-container .upload-control {
  margin-inline: -20px;
}
.upload-controls .products-container .product-card {
  height: 125px;
  font-size: 0.9rem;
}

.upload-container .upload-footer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  align-items: center;
  padding: 1em;
  margin-bottom: 1em;
}
.upload-container .upload-footer button {
  width: 100%;
  padding-block: 1em;
  font-size: 0.95em;
  border: 1px solid rgb(146, 146, 146);
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}
.upload-container .upload-footer button:hover {
  border: 1px solid black;
}
.upload-container .upload-footer button.button-red {
  background-color: rgb(255, 39, 75);
  border: 1px solid rgb(255, 39, 75);
  color: white;
}
.upload-container .upload-footer button.button-red:hover {
  background-color: rgb(255, 0, 0);
  border: 1px solid rgb(255, 0, 0);
}
