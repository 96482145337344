.reels-scroll {
  width: 100%;
  height: max(100vh, 500px);
  overflow: auto;
  scrollbar-track-color: transparent;
  scrollbar-color: transparent transparent;
  scroll-snap-type: y mandatory;
}

.video-container {
  height: max(100vh, 500px);
  width: min(100%, 430px);
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  scroll-snap-align: center;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* top to botton linear gradient of black getting darker */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75)
  );
}

.user-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.video-controls {
  position: absolute;
  bottom: -50px;
  right: 0px;
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: white;
  cursor: pointer;
}

.profile {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 18px;
}

.profile img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.profile b {
  cursor: pointer;
}

.profile button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  margin-right: 5px;
  font-weight: 300;
  cursor: pointer;
}

.follow-btn {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  padding: 3px 1.1em;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.overlay .product-info {
  min-height: 7.5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.overlay .product-info button {
  padding: 10px 15px;
  width: fit-content;
  position: absolute;
  top: -40px;
  right: 0;
  background-color: yellow;
  border: none;
  border-radius: 40px;
  font-size: 0.95em;
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.3s;
  animation: thump 2s infinite;
}

.overlay .product-info button i {
  margin-right: 5px;
}

.overlay .product-info button:hover {
  scale: 1.1;
}

@keyframes thump {
  0% {
    scale: 1;
  }

  25% {
    scale: 1.1;
  }

  50% {
    scale: 1;
  }

  100% {
    scale: 1;
  }
}

.product-info span {
  width: 80%;
  margin-left: 10px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-all;
}

.fa-play {
  font-size: 45px;
  color: white;
  text-align: center;
}
